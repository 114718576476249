define('@fortawesome/pro-light-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faCirclePlus = {
    prefix: 'fal',
    iconName: 'circle-plus',
    icon: [512, 512, ["plus-circle"], "f055", "M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM240 352c0 8.8 7.2 16 16 16s16-7.2 16-16V272h80c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V160c0-8.8-7.2-16-16-16s-16 7.2-16 16v80H160c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v80z"]
  };

  exports.faCirclePlus = faCirclePlus;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
