define("ember-intersection-observer-modifier/modifiers/observe-intersection", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @param {HTMLElement} element
   * @param {[IntersectionObserverCallback, Partial<IntersectionObserverInit>]} positionalParams
   * @param {Partial<IntersectionObserverInit>} hashOptions
   */
  function observeIntersection(element, [callback, optionsObject], hashOptions) {
    const options = {
      ...optionsObject,
      ...hashOptions
    };
    const observer = new IntersectionObserver(callback, options);
    observer.observe(element);
    return function cleanupObserver() {
      observer.disconnect();
    };
  }
  var _default = _exports.default = (0, _emberModifier.modifier)(observeIntersection);
});